<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>训练营列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
          <el-row>
            <el-col :span="6">
              <el-form-item label="企业名称">
                <el-input v-model="search.company_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="门店名称">
                <el-input v-model="search.store_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上下架状态">
                <el-select v-model="search.publish_state" placeholder="请选择状态" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="上架" :value="1"></el-option>
                  <el-option label="下架" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="审核状态">
                <el-select v-model="search.examine_status" placeholder="请选择状态" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="审核中" :value="0"></el-option>
                  <el-option label="审核通过" :value="1"></el-option>
                  <el-option label="审核失败" :value="2"></el-option>
                  <el-option label="违规下架" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="课程标题">
                <el-input v-model="search.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="课程名称">
                <el-input v-model="search.lesson_name"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="教练昵称">
                <el-input v-model="search.user_nick"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="教练姓名">
                <el-input v-model="search.user_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上课状态">
                <el-select v-model="search.lesson_state" placeholder="请选择状态" style="width: 100%">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="未开始" :value="1"></el-option>
                  <el-option label="进行中" :value="2"></el-option>
                  <el-option label="已下课" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上课日期">
                <el-date-picker v-model="search.date_ymd" type="date" value-format="yyyy-MM-dd" style="width: 100%"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
              @click="issearch = !issearch">隐藏搜索
            </el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="issearch = !issearch">显示搜索
            </el-button>
            <!-- <el-button v-if="is_auth('lessonschedule.camp.issave')" icon="el-icon-plus"
                                   size="medium" type="primary" @click="tosee()">添加
                        </el-button> -->
            <el-button icon="el-icon-download" size="medium" type="primary" @click="exportList">导出
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column prop="company_name" label="所属企业" width="100">
        </el-table-column>
        <el-table-column prop="store_name" label="门店" width="200">
          <template slot-scope="scope">
            <span>【{{ scope.row.store_company_name }}】{{ scope.row.store_name }}—{{
              scope.row.classroom_name
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="课程标题" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="user_nick" label="教练昵称" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.user_nick">
              <el-avatar shape="square" :size="22" :src="scope.row.avatar_url"></el-avatar>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>{{ scope.row.user_nick }}</span>
            </div>
            <span v-else>无教练</span>
          </template>
        </el-table-column>
        <el-table-column prop="lesson_duration" label="上课时间" width="100">
          <template slot-scope="scope">
            <el-popover placement="right" width="200" trigger="click">
              <el-table :data="scope.row.lesson_time">
                <el-table-column width="200" property="date" label="上课时间">
                  <template slot-scope="scope2">
                    {{ scope2.row }}
                  </template>
                </el-table-column>
              </el-table>
              <el-button type="text" size="mini" slot="reference">查看，共{{ scope.row.lesson_sum }}节
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="start_time" label="开始时间" width="150">
        </el-table-column>
        <el-table-column prop="end_time" label="结束时间" width="150"> </el-table-column>
        <el-table-column prop="quota" label="人数上限" width="50">
          <template slot-scope="scope">
            <span>{{ scope.row.quota }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="bookings_sum" label="预约人数" width="50">
          <template slot-scope="scope">
            <el-link v-if="scope.row.bookings_sum > 0 && is_auth('order.camp.getlist')" type="primary"
              @click="openOrderlist(scope.row)">
              {{ scope.row.bookings_sum }}
            </el-link>
            <span v-else>{{ scope.row.bookings_sum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="bookings_min" label="开课人数" width="50">
          <template slot-scope="scope">
            <span>{{ scope.row.bookings_min }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="is_queue_up" label="允许等候" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.is_queue_up === 1">是</el-tag>
            <el-tag size="mini" v-if="scope.row.is_queue_up === 0" type="warning">否</el-tag>
            <span v-if="scope.row.is_queue_up === 1">（{{ scope.row.queue_up_sum }}）</span>
          </template>
        </el-table-column>
        <el-table-column prop="is_queue_up" label="允许退款" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.is_refund === 1">是</el-tag>
            <el-tag size="mini" v-if="scope.row.is_refund === 0" type="warning">否</el-tag>
            <span v-if="scope.row.is_refund === 1">（{{ scope.row.refund_sum }}）</span>
          </template>
        </el-table-column>
        <el-table-column prop="lesson_state" label="上课状态" width="80">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.lesson_state === 1">未开始</el-tag>
            <el-tag size="mini" v-if="scope.row.lesson_state === 2" type="warning">进行中</el-tag>
            <el-tag size="mini" v-if="scope.row.lesson_state === 3" type="success">已下课</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="publish_state" label="上下架状态" width="200">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.publish_state === 0" type="warning">下架</el-tag>
            <el-tag size="mini" v-if="scope.row.publish_state === 1" type="success">上架</el-tag>
            <span v-if="scope.row.publish_state === 1">&nbsp;{{ scope.row.publish_time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="examine_status" label="审核状态" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.examine_status === 0">审核中</el-tag>
            <el-tag size="mini" v-if="scope.row.examine_status === 1" type="success">审核通过</el-tag>
            <el-tag size="mini" v-if="scope.row.examine_status === 2" type="danger">审核失败</el-tag>
            <el-tag size="mini" v-if="scope.row.examine_status === 3" type="danger">违规下架</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lesson_name" label="课程名称" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.lesson_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.originally_price > 0" class="text-decoration-price">￥{{ scope.row.originally_price
            }}</span>
            <span>￥{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lesson_name" label="课程类型" width="80">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.lesson_type === 1">团课</el-tag>
            <el-tag size="mini" v-if="scope.row.lesson_type === 2" type="success">训练营</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lesson_duration" label="课程时长(单节)" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.lesson_duration }}分钟</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180">
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="180">
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="230">
          <template slot-scope="scope">
            <el-button v-if="is_auth('lessonschedule.camp.getinfo')" @click="tosee(scope.row.good_uuid)" size="mini">查看
            </el-button>
            <el-button v-if="is_auth('lessonschedule.camp.examine') && scope.row.examine_status === 0"
              @click="operation_tip(scope.row.good_uuid, scope.row.lesson_name, 'examine', 1)" type="success"
              size="mini">通过
            </el-button>
            <el-button v-if="is_auth('lessonschedule.camp.examine') && scope.row.examine_status === 0"
              @click="operation_tip(scope.row.good_uuid, scope.row.lesson_name, 'examine', 2)" type="danger"
              size="mini">不通过
            </el-button>
            <el-button
              v-if="is_auth('lessonschedule.camp.examine') && scope.row.examine_status !== 0 && scope.row.examine_status !== 3"
              @click="operation_tip(scope.row.good_uuid, scope.row.lesson_name, 'illegal', 3)" type="danger"
              size="mini">违规下架
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>
      <!--预约人数-->
      <el-dialog v-if="is_auth('store.coach.getcoach')" title="训练营订单" :visible.sync="OrderlistPage" width="80%"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <el-row>
          <Orderlist ref="orderlist" />
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Orderlist from "./page/Orderlist";

export default {
  components: {
    Orderlist,
  },
  data() {
    return {
      page_name: "训练营管理",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
      OrderlistPage: false, // 预约信息
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() { },
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        store_name: "",
        publish_state: "",
        state: "",
        date_ymd: "",
        title: "",
        lesson_name: "",
        user_nick: "",
        user_name: "",
        lesson_state: "",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "lessonschedule.camp.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 操作提示
    operation_tip(good_uuid = "", title = "", operation = "", state) {
      let tip = "";
      let options = {};
      // 启用
      if (operation === "examine") {
        tip = "启用【" + title + "】？";
        if (state === 1) {
          tip = '审批通过【' + title + '】？';
          // 弹出二次确认
          this.$confirm(tip, "确认信息", options).then(() => this.isoperation(good_uuid, operation, state))
        } else {
          tip = '不通过【' + title + '】？';
          // 弹出二次确认
          this.$prompt("请输入不通过理由", tip).then(({ value }) => this.isoperation(good_uuid, value, state))
        }
      }
      if (operation === "illegal") {
        tip = "违规下架【" + title + "】？";
        // 弹出二次确认
        this.$prompt("请输入违规下架原因", tip).then(({ value }) => this.isoperation(good_uuid, value, state))
      }
    },
    // 操作
    isoperation(good_uuid = "", value, state) {
      let postdata = {
        api_name: "lessonschedule.camp.examine",
        token: this.Tool.get_l_cache("token"),
        good_uuid,
        examine_status: state
      };
      if (value) {
        postdata["examine_reason"] = value;
      }
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 查看
    tosee(good_uuid = "") {
      // if (good_uuid === '') {
      //     this.$router.push({path: '/lessonschedule/camp/edit'})
      // } else {
      //     this.$router.push({path: '/lessonschedule/camp/edit', query: {good_uuid: good_uuid}})
      // }
      this.$router.push({
        path: "/lessonschedule/camp/check",
        query: { good_uuid: good_uuid },
      });
    },
    // 打开订单
    openOrderlist(row) {
      let thi = this;
      this.OrderlistPage = true;
      setTimeout(() => {
        thi.$refs.orderlist.init(row.good_uuid);
      }, 100);
    },
    exportList() {
      let postdata = {
        api_name: "lessonschedule.camp.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
        export: "yes",
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        if (res.code === 10005) {
          this.Tool.errormes(res);
        } else {
          location.href = res;
        }
        this.loading = false;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.form-tag_colour-div {
  min-height: 200px;
}
</style>
